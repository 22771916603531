<template>
  <div class="container">
    <div class="title-wrap moderation-page-title">
      <h1 class="title-top">
        Модерация заявок
        <a href="javascript:void(0);" class="btn btn-outline-secondary moder-filter"
          >Поиск и фильтр</a
        >
      </h1>
      <div class="title">
        <div class="title__left d-flex align-items-center">
          <b-button-group>
            <b-button
              type="button"
              :variant="filter === 'new' ? 'primary' : 'outline-default'"
              data-tab="tabNews"
              @click="filter = 'new'"
              >Свежие <span class="request__top-btn-count">{{ newCount }}</span></b-button
            >
            <b-button
              :variant="filter === 'accepted' ? 'primary' : 'outline-default'"
              @click="filter = 'accepted'"
              >Одобренные</b-button
            >
            <b-button
              :variant="filter === 'rejected' ? 'primary' : 'outline-default'"
              @click="filter = 'rejected'"
              >Отклоненные</b-button
            >
            <b-button
              :variant="filter === 'abuse' ? 'primary' : 'outline-default'"
              @click="filter = 'abuse'"
              >Жалобы</b-button
            >
          </b-button-group>

          <div class="moderation-filter">
            <div class="title__right-btns">
              <div class="request__top-search">
                <input type="text" placeholder="Поиск (дата или ник)" />
                <a href="javascript:void(0);" class="btn btn-outline-secondary"></a>
              </div>
            </div>
            <div v-if="filter === 'abuse'" class="title__right-btns title__right-b-ch">
              <div class="title__right-checkbox d-flex">
                Фильтр:
                <div class="home-form-col__checkbox-wrap">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="ooo" checked="" />
                    <label class="custom-control-label" for="ooo">Жалобы</label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="self-employed"
                      checked=""
                    />
                    <label class="custom-control-label" for="self-employed">Апелляции</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="moderation-filter-btn">
              <a href="javascript:void(0);" class="btn btn-outline-secondary">Сбросить</a>
              <a href="javascript:void(0);" class="btn btn-primary custom-btn custom-btn-gold"
                >Найти</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-tabs__content is-active" id="tabReport" v-if="filter === 'abuse'">
      <h2 class="admin__subtitle">Обработка жалоб</h2>

      <div class="admin__flex">
        <new-abuse-card
          v-for="abuse in abusesShow"
          :key="abuse.id"
          :abuse="abuse"
          @hide="() => reloadAbuses()"
        />
        <button v-if="abuses.length > 2" class="btn--white admin__btn-more" @click="abusePage += 2">
          Показать ещё
        </button>
      </div>
    </div>
    <div class="admin-tabs__content is-active" id="tabNews" v-else>
      <div class="admin__wrap" v-if="filter === 'new'">
        <h2 class="admin__subtitle">Модерация аккаунтов на ВП</h2>
        <div class="admin__flex">
          <div v-if="newShow.length === 0">Нет аккаунтов</div>
          <mod-card
            v-for="account in newShow"
            :account="account"
            :key="account.id"
            @forRevision="forRevision(...arguments)"
            @cancel="cancel(...arguments)"
            @accepted="accept($event)"
          />
          <button
            v-if="newAccounts.length > 2"
            class="btn--white admin__btn-more"
            @click="newPage += 2"
          >
            Показать ещё
          </button>
        </div>
      </div>
      <div class="admin__wrap" v-if="filter === 'accepted'">
        <h2 class="admin__subtitle">Модерация аккаунтов на ВП</h2>
        <div class="admin__flex">
          <div v-if="acceptedAccounts.length === 0">Нет аккаунтов</div>
          <mod-card
            v-for="account in acceptedAccounts"
            :account="account"
            :key="account.id"
            @forRevision="forRevision(...arguments)"
            @cancel="cancel(...arguments)"
            @accepted="accept($event)"
          />
          <button
            v-if="acceptedAccounts.length > 2"
            class="btn--white admin__btn-more"
            @click="acceptedPage += 2"
          >
            Показать ещё
          </button>
        </div>
      </div>
      <div class="admin__wrap" v-if="filter === 'rejected'">
        <h2 class="admin__subtitle">Модерация аккаунтов на ВП</h2>
        <div class="admin__flex">
          <div v-if="rejectedAccounts.length === 0">Нет аккаунтов</div>
          <mod-card
            v-for="account in rejectedAccounts"
            :account="account"
            :key="account.id"
            @forRevision="forRevision(...arguments)"
            @cancel="cancel(...arguments)"
            @accepted="accept($event)"
          />
          <button
            v-if="rejectedAccounts.length > 2"
            class="btn--white admin__btn-more"
            @click="rejectedPage += 2"
          >
            Показать ещё
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moderApi from '@mp/api/moderation';
import ModCard from './ModCard.vue';
import NewAbuseCard from './NewAbuseCard.vue';

export default {
  components: {
    ModCard,
    NewAbuseCard,
  },
  data() {
    return {
      showReasonPopup: false,
      abuseCount: 0,
      filter: 'new',
      newAccounts: [],
      acceptedAccounts: [],
      rejectedAccounts: [],
      abuses: [],
      acceptedPage: 2,
      newPage: 2,
      rejectedPage: 2,
      abusePage: 2,
    };
  },
  computed: {
    newCount() {
      return this.newAccounts.length;
    },
    acceptedShow() {
      if (this.acceptedAccounts.length === this.acceptedPage) {
        return this.acceptedAccounts;
      }
      return this.acceptedAccounts.slice(0, this.acceptedPage);
    },
    newShow() {
      if (this.newAccounts.length === this.newPage) {
        return this.newAccounts;
      }
      return this.newAccounts.slice(0, this.newPage);
    },
    abusesShow() {
      if (this.abuses.length === this.abusePage) {
        return this.abuses;
      }
      return this.abuses.slice(0, this.abusePage);
    },
  },
  methods: {
    loadMore() {
      this.newPage += 2;
    },
    async accept(accountId) {
      await moderApi.acceptedAccount(accountId);
      this.reload();
    },
    async cancel(accountId, reason) {
      await moderApi.rejectedAccount(accountId, reason);
      this.reload();
    },
    async forRevision(accountId, reason) {
      await moderApi.rejectedAccount(accountId, reason);
      this.reload();
    },
    async reload() {
      this.newAccounts = await moderApi.getNewAccounts();
      this.acceptedAccounts = await moderApi.getAcceptedAccounts();
      this.rejectedAccounts = await moderApi.getRejectedAccounts();
      this.abuses = await moderApi.getAbuses();
    },
    async reloadAbuses() {
      this.abuses = await moderApi.getAbuses();
    },
  },
  mounted() {
    this.reload();
  },
};
</script>

<style lang="scss">
.admin__subtitle {
  margin-top: 25px;
}
</style>
