<template>
  <div class="admin__steps">
    <div class="admin__flex-item admin-news admin__step is-active">
      <div class="admin-news__top">
        <a :href="instagramLink" target="_blank" class="link-blue admin-news__name">{{
          account.blogger_profile.instaname
        }}</a>
        <span class="admin-news__steps">{{ stepTitle }}</span>
      </div>
      <div class="admin-news__content" v-if="currentStep === 1">
        <div class="admin-news__content-top">
          <div class="admin-news__moder">
            <span class="admin-news__moder-label">Охваты сторис</span>
            <div class="admin-news__moder-flex mgbottom">
              <div class="admin-news__moder-wrap">
                <label class="label admin-news__moder--label">Минимум</label>
                <input type="text" class="input admin-news__moder-input" v-model="coverageMin" />
              </div>
              <div class="admin-news__moder-wrap">
                <label class="label admin-news__moder--label">Максимум</label>
                <input type="text" class="input admin-news__moder-input" v-model="coverageMax" />
              </div>
            </div>
            <div v-if="account.blogger_profile.moderator_reject">
              <span class="admin-news__moder-label">Причина отклонения:</span>
              <div>
                {{
                  account.blogger_profile.reject_reason
                    ? account.blogger_profile.reject_reason
                    : 'Причина не указана'
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="moder__content-link">
          <a @click="() => (showOriginalScreensModal = true)" class="open-screenshots"
            >Оригиналы скриншотов</a
          >
        </div>
        <div v-if="account.blogger_profile.coverage_screen.thumbnail" class="moder__content-img">
          <a :href="account.blogger_profile.coverage_screen.full_size">
            <img :src="account.blogger_profile.coverage_screen.thumbnail" alt="img" />
          </a>
        </div>
      </div>
      <div class="admin-news__content" v-if="currentStep === 2">
        <div class="admin-news__content-top">
          <div class="admin-news__moder">
            <div class="admin-news__moder-flex mgbottom">
              <div class="admin-news__moder-item">
                <span class="admin-news__moder-label">Преобладающая страна в процентах</span>
                <div class="admin-news__moder-flex" v-if="account.blogger_profile.main_country">
                  <div class="admin-news__moder-flex-item small full-text">
                    <div class="dropdown admin-news__moder-dropdown">
                      <span class="dropdown__input admin-news__moder-dropdown-input">Россия</span>
                      <div class="dropdown__list">
                        <div class="dropdown__item">
                          <input
                            id="checkitem22-1"
                            type="checkbox"
                            class="dropdown__input"
                            hidden
                            checked="checked"
                          />
                          <label for="checkitem22-1" class="dropdown__label">Россия</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="admin-news__moder-flex-item admin-news__moder-flex-item--input">
                    <input type="text" class="input admin-news__moder-input new-line" value="54" />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="account.blogger_profile.main_country.screen.thumbnail"
              class="moder__content-img"
            >
              <a :href="account.blogger_profile.main_country.screen.full_size">
                <img :src="account.blogger_profile.main_country.screen.thumbnail" alt="img" />
              </a>
            </div>
            <div class="admin-news__moder-flex" v-if="!account.blogger_profile.main_country">
              <p>Информация не указана</p>
            </div>
          </div>
        </div>
        <div class="admin-news__content-img-wrap"></div>
      </div>
      <div class="admin-news__content" v-if="currentStep === 3">
        <div class="admin-news__content-top">
          <div class="admin-news__moder">
            <div class="admin-news__moder-flex mgbottom">
              <div class="admin-news__moder-item">
                <span class="admin-news__moder-label">Преобладающий возраст в процентах</span>
                <div class="admin-news__moder-flex" v-if="account.blogger_profile.main_audience">
                  <div class="admin-news__moder-flex-item s-small">
                    <div class="dropdown admin-news__moder-dropdown">
                      <span class="dropdown__input admin-news__moder-dropdown-input">18-24</span>
                      <div class="dropdown__list">
                        <div class="dropdown__item">
                          <input
                            id="checkitem2-12"
                            type="checkbox"
                            class="dropdown__input"
                            hidden
                            checked="checked"
                          />
                          <label for="checkitem2-12" class="dropdown__label">Россия</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="admin-news__moder-flex-item admin-news__moder-flex-item--input">
                    <input type="text" class="input admin-news__moder-input new-line" value="45" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="account.blogger_profile.main_audience.screen.thumbnail"
          class="moder__content-img"
        >
          <a :href="account.blogger_profile.main_audience.screen.full_size">
            <img :src="account.blogger_profile.main_audience.screen.thumbnail" alt="img" />
          </a>
        </div>
      </div>
      <div class="admin-news__content" v-if="currentStep === 4">
        <div class="admin-news__content-top">
          <div class="admin-news__moder">
            <span class="admin-news__moder-label big">Тезисы</span>
            <div class="textarea" data-symbol="297/300">
              <textarea
                class="admin-news__moder-textarea"
                disabled
                v-model="account.blogger_profile.theses"
              ></textarea>
            </div>
            <span class="admin-news__moder-label big">1-3 темы аккаунта</span>
            <div class="dropdown">
              <span class="dropdown__input">{{ themes }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-news__footer">
        <div class="admin-news__dropdown">
          <div
            class="dropdown admin-news__dropdown-item admin-news__dropdown-item--large"
            @click="reject('forRevision')"
            v-if="currentStep === 1 && !account.blogger_profile.moderator_reject"
          >
            <span class="btn--border">На доработку</span>
          </div>
          <div
            class="admin-news__dropdown-item admin-news__dropdown-item--small"
            @click="reject('cancel')"
            v-else-if="!account.blogger_profile.moderator_reject"
          >
            <span class="btn--border">Отказать</span>
          </div>
        </div>
        <button
          v-if="currentStep > 1"
          type="button"
          class="admin-news__btn-back"
          @click="currentStep -= 1"
          :disabled="currentStep === 1"
        >
          <svg class="admin-news__btn-back-icon">
            <use xlink:href="../assets/sprite.svg#arrow"></use>
          </svg>
        </button>
        <button type="button" class="btn--blue" v-if="currentStep < 4" @click="currentStep += 1">
          Далее
        </button>
        <button
          type="button"
          class="btn--orange"
          v-if="currentStep === 4 && !account.blogger_profile.is_moderated"
          @click="() => $emit('accepted', account.id)"
        >
          Одобрить
        </button>
      </div>
    </div>
    <Modal
      wrapperClass="big-popup screenshots-popup wrap-popup"
      contentClass="wrap-popup__inner medium-popup__inner"
      :show="showOriginalScreensModal"
      @hide="() => (showOriginalScreensModal = false)"
      title="Оригиналы скриншотов"
    >
      <div v-if="account.blogger_profile.coverage_screen.full_size">
        <p>Охваты сторис</p>
        <img :src="account.blogger_profile.coverage_screen.full_size" />
      </div>
      <div v-if="account.blogger_profile.main_country.screen.full_size">
        <p>Страна</p>
        <img :src="account.blogger_profile.main_country.screen.full_size" />
      </div>
      <div v-if="account.blogger_profile.main_audience.screen.full_size">
        <p>Аудитория</p>
        <img :src="account.blogger_profile.main_audience.screen.full_size" />
      </div>
    </Modal>
    <Modal
      title="Укажите причину отклонения"
      :show="showReasonPopup"
      @hide="() => (showReasonPopup = false)"
      contentClass="reason--popup"
      wrapperClass="reasons__wrap"
    >
      <div
        class="popup-verify-prem__textarea textarea reasons--text"
        :data-symbol="rejectReason.length + '/500'"
      >
        <textarea required v-model.trim="rejectReason" maxlength="500"></textarea>
        <div class="popup-verify-prem-exmpl"></div>
      </div>
      <div class="refuse-reason__footer">
        <button
          type="button"
          class="btn--orange popup-verify-prem__btn js-btnPopup btn__reasons"
          :disabled="!rejectReason.length && !reasons.length"
          @click="() => $emit(rejectType, account.id, rejectReason)"
        >
          Готово
        </button>
        <div class="reason__select">
          <multiselect
            v-model="reasons"
            :options="
              currentStep === 1 && !account.blogger_profile.moderator_reject
                ? reasons_upd
                : reasons_decl
            "
            :multiple="true"
            placeholder="Причины отклонения"
            selectedLabel=""
            label="name"
            track-by="name"
            selectLabel
            deselectLabel
          >
          </multiselect>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    account: {
      type: Object,
    },
  },
  components: {
    Modal,
    Multiselect,
  },
  data() {
    return {
      rejectReason: '',
      reasons: [],
      rejectType: null,
      showReasonPopup: false,
      showOriginalScreensModal: false,
      currentStep: 1,
      coverageMin: this.account.blogger_profile.coverage_min,
      coverageMax: this.account.blogger_profile.coverage_max,
      reasons_upd: [
        { name: 'Несоответствие преобладающей страны скрину', value: 1 },
        { name: 'Несоответствие преобладающего возраста скрину', value: 2 },
        { name: 'Запрещенные тематики в тезисах', value: 3 },
        { name: 'Скрин по странам обрезан', value: 4 },
        { name: 'Скрин по возрасту обрезан', value: 5 },
      ],
      reasons_decl: [
        { name: 'Несоответствие указанных охватов скрину', value: 1 },
        { name: 'Скрин по охватами обрезан', value: 6 },
        { name: 'Старый скрин по охватам', value: 7 },
        { name: 'Подделка скринов', value: 8 },
        { name: 'Накрученная статистика', value: 9 },
        { name: 'Скрин не ваш', value: 10 },
        { name: 'Вы в ЧС', value: 11 },
      ],
    };
  },
  computed: {
    themes() {
      return this.account.blogger_profile.tags.map((r) => r.name).join(', ');
    },
    stepTitle() {
      switch (this.currentStep) {
        case 1:
          return 'Шаг 1-1 из 2. Статистика';
        case 2:
          return 'Шаг 1-2 из 2. Статистика';
        case 3:
          return 'Шаг 1-3 из 2. Статистика';
        case 4:
          return 'Шаг 2 из 2. Тезисы';
        default:
          return '';
      }
    },
    instagramLink() {
      return `https://www.instagram.com/${this.account.blogger_profile.instaname}/`;
    },
  },
  methods: {
    reject(type) {
      this.rejectType = type;
      this.showReasonPopup = true;
    },
  },
};
</script>

<style scoped lang="scss">
.link-blue {
  display: inline;
}
.dropdown__input::after {
  content: none;
}
.refuse-reason__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 700px;
  margin: 30px;
}
.reason--text {
  margin: 30px !important;
}
.reason__select {
  width: 80%;
}
.small .fill-text {
  font-size: 100%;
}
.reason--popup {
  padding: 20px !important;
}
.btn__reasons {
  height: 40px;
}
.reasons__wrap {
  max-width: 800px !important;
}
::v-deep .popup__wrap {
  max-width: 900px;
}
</style>
