<template>
  <div class="dropdown bootstrap-select white-select" :class="classes">
    <select class="selectpicker white-select" :title="title" tabindex="-98"></select>
    <button
      type="button"
      class="btn dropdown-toggle btn-light bs-placeholder"
      data-toggle="dropdown"
      role="button"
      :title="title"
      aria-expanded="false"
    >
      <div class="filter-option">
        <div class="filter-option-inner">
          <div class="filter-option-inner-inner">{{ title }}</div>
        </div>
      </div>
    </button>
    <div
      class="dropdown-menu"
      role="combobox"
      style="max-height: 577px; overflow: hidden; min-height: 99px; min-width: 308px"
    >
      <div
        class="inner show"
        role="listbox"
        aria-expanded="false"
        tabindex="-1"
        style="max-height: 565px; overflow-y: auto; min-height: 87px"
      >
        <ul class="dropdown-menu inner show">
          <li v-if="menuTitle" class="disabled">
            <a
              role="option"
              class="dropdown-item disabled"
              aria-disabled="true"
              tabindex="-1"
              aria-selected="false"
              ><span class="text"> {{ menuTitle }} </span></a
            >
          </li>
          <li
            @click="() => $emit(`option-${option.id}`, option)"
            v-for="(option, idx) in options"
            :key="`${option.id}-${option.name}-${idx}-optnDc`"
          >
            <a
              role="option"
              class="dropdown-item"
              aria-disabled="false"
              tabindex="0"
              aria-selected="false"
              ><span class="text"> {{ option.name }}</span></a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
    title: {
      type: String,
    },
    styleString: {
      type: String,
    },
    classes: {
      type: [Object, Array],
    },
    menuTitle: {
      type: String,
    },
  },
};
</script>
