<template>
  <div class="col-lg-6 moder__item">
    <div class="moder__inner">
      <a
        :href="'https://instagram.com/' + abuse.profile.blogger_profile.instaname"
        class="moder__name"
        ><span class="black">Жалоба на </span>{{ abuse.profile.blogger_profile.instaname }}</a
      >
      <div class="moder__content moder__content-tap--fixed">
        <div class="moder__content-i active">
          <div class="moder__content-tap">
            Жалоб на блогера за 2 месяца: {{ stat.abuse_count }}. Осталось жизней:
            {{ abuse.profile.mutualpr_profile.strikes }}
          </div>

          <div
            class="moder__content-inner scroll"
            :style="{ overflow: $root.modalOpen ? 'hidden' : 'auto' }"
          >
            <div class="moder__content-actions">
              <div class="moder__content-actions-l">
                От:
                <a
                  :href="'https://instagram.com/' + abuse.initiator.blogger_profile.instaname"
                  target="_blank"
                  >{{ abuse.initiator.blogger_profile.instaname }}</a
                >
                <br />
                <div class="dark-grey">
                  Отправил(-а) жалоб за 2 месяца: {{ init_stat.create_abuse }}
                </div>
              </div>
              <div v-if="!!isMobile" class="moder__content-actions-r marg-r-l">
                <DefaultDropdownMenu
                  :options="initiatorReasons"
                  title="Действия"
                  menuTitle="Укажите действие"
                />
              </div>
            </div>
            <div class="moder__content-i-txt">
              Причина: Блогер вообще не выложил рекламу на меня
              <br />
              <br />
              E-mail: {{ abuse.email }} <a v-clipboard:copy="abuse.email">Скопировать</a><br />

              <br />
              <span class="dark-grey fs-14">Текст жалобы</span> <br />
              <span class="fs-14">
                {{ abuse.text }}
              </span>
              <br />
              <br />
              Скриншоты или видео <br />
              <a @click="() => (showScreensModal = true)" class="fs-14 open-screenshots"
                >Посмотреть оригиналы</a
              >
              <br />
              <div class="moder__content-i-txt-screen">
                <div
                  v-for="screen in abuse.screenshots"
                  :key="screen.id"
                  class="moder__content-img"
                >
                  <img :src="screen.content.thumbnail" alt="img" class="mCS_img_loaded" />
                </div>
                <a
                  @click="() => (videoModal = true)"
                  class="btn btn-outline-secondary example-evidence-popup-btn"
                  >Смотреть видео</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!!isMobile" class="moder__bottom">
        <div class="moder__bottom-left">
          <div class="form-group m-0 custom-small-select">
            <DefaultDropdownMenu :classes="['dropup']" title="Простой отказ" />
          </div>
          <div class="form-group m-0 custom-small-select"></div>
        </div>
        <div class="moder__bottom-right">
          <div class="form-group m-0 custom-small-select">
            <DefaultDropdownMenu
              :options="blockReasons"
              menuTitle="Укажите причину"
              :classes="['dropup']"
              title="Заблокировать"
              @option-1="block"
              @option-2="block"
              @option-3="block"
            />
          </div>
        </div>
      </div>
      <div v-else class="moder__bottom abuse-bot">
        <button @click="() => (initiatorActionModal = true)" class="btn--border btn_abuse-bottom">
          Действия для инициатора
        </button>
        <button @click="() => (abuseRejectModal = true)" class="btn--border btn_abuse-bottom">
          Отклонение жалобы
        </button>
        <button @click="() => (abuseBlockModal = true)" class="btn--border btn_abuse-bottom">
          Блокировка подозреваемого
        </button>
      </div>
    </div>
    <Modal
      @hide="() => (showScreensModal = false)"
      :show="showScreensModal"
      title="Оригиналы скриншотов"
    >
      <div v-for="screen in abuse.screenshots" :key="screen.id" class="moder__content-img">
        <img :src="screen.content.thumbnail" alt="img" class="mCS_img_loaded" />
      </div>
    </Modal>
    <Modal @hide="() => (videoModal = false)" :show="videoModal" title="Пример доказательств">
      <iframe
        width="560"
        height="315"
        :src="abuse.video"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </Modal>
    <SelectItemModal
      :options="initiatorReasons"
      title="Выберите действие для инициатора"
      @hide="() => (initiatorActionModal = false)"
      @option-1="block"
      @option-2="block"
      @option-3="block"
      :show="initiatorActionModal"
    />
    <SelectItemModal
      :options="initiatorReasons"
      title="Выберите действие для подозреваемого"
      @hide="() => (abuseBlockModal = false)"
      @option-1="block"
      @option-2="block"
      @option-3="block"
      :show="abuseBlockModal"
    />
  </div>
</template>

<script>
import DefaultDropdownMenu from '@main/components/reusable/elements/dropdowns/DefaultDropdownMenu.vue';
import SelectItemModal from '@main/components/reusable/modals/SelectItemModal.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import * as moderApi from '@mp/api/moderation';
import { mapState } from 'vuex';

export default {
  components: {
    Modal,
    DefaultDropdownMenu,
    SelectItemModal,
  },
  props: {
    abuse: {
      type: Object,
    },
  },
  computed: {
    ...mapState(['isMobile', 'isTablet']),
    reason() {
      switch (this.abuse.reason) {
        case 0:
          return 'Блогер вообще не выложил рекламу на меня';
        case 1:
          return 'Блогер обманул меня со статистикой';
        default:
          return 'Иная причина';
      }
    },
  },
  data() {
    return {
      stat: {},
      mode: null,
      videoModal: false,
      initiatorActionModal: false,
      abuseBlockModal: false,
      abuseRejectModal: false,
      actionOpen: false,
      showBlockedModal: false,
      showScreensModal: false,
      init_stat: {},
      blockReasons: [
        { id: 1, name: 'Отнять жизнь за обман с рекламой' },
        { id: 2, name: 'Отнять жизнь за обман со статистикой' },
        { id: 3, name: 'Заблокировать на ВП' },
      ],
      initiatorReasons: [
        { id: 1, name: 'Заблокировать' },
        { id: 2, name: 'Не принимать жалобы 1 месяц' },
        { id: 3, name: 'Больше никогда не принимать жалобы' },
      ],
    };
  },
  methods: {
    async block(payload) {
      switch (payload.name) {
        case 'Отнять жизнь за обман с рекламой':
          await moderApi.actionAbuse(this.abuse.id, 'profile_set_strike_ad');
          this.mode = 'strike';
          setTimeout(this.hide(), 5000);
          break;
        case 'Отнять жизнь за обман со статистикой':
          await moderApi.actionAbuse(this.abuse.id, 'profile_set_strike_stat');
          this.mode = 'strike';
          this.hide();
          break;
        case 'Заблокировать на ВП':
          await moderApi.actionAbuse(this.abuse.id, 'profile_block');
          break;
        case 'Заблокировать':
          await moderApi.actionAbuse(this.abuse.id, 'initiator_block');
          this.hide();
          break;
        case 'Не принимать жалобы 1 месяц':
          await moderApi.actionAbuse(this.abuse.id, 'initiator_mute_1');
          this.hide();
          break;
        case 'Больше никогда не принимать жалобы':
          await moderApi.actionAbuse(this.abuse.id, 'initiator_mute');
          this.hide();
          break;
        default:
          break;
      }
    },
    hide() {
      this.$emit('hide');
    },
    async initBlockedRequest() {
      this.showBlockedModal = true;
      this.actionOpen = false;
      await moderApi.rejectedAbuse(this.abuse.id);
      this.hide();
    },
    async initBlocked() {
      this.showBlockedModal = false;
      await moderApi.rejectedAbuse(this.abuse.id);
      this.hide();
    },
    async disableAbuse1() {
      this.actionOpen = false;
      await moderApi.rejectedAbuse(this.abuse.id);
      this.hide();
    },
    async disableAbuseAll() {
      this.actionOpen = false;
      await moderApi.rejectedAbuse(this.abuse.id);
      this.hide();
    },
  },
  async mounted() {
    this.stat = await moderApi.getStatAccount(this.abuse.profile.id);
    if (this.abuse.initiator) {
      this.init_stat = await moderApi.getStatAccount(this.abuse.initiator.id);
    }
    console.log(this.isMobile);
  },
};
</script>

<style lang="scss">
.marg-r-l {
  margin-right: 3px;
}
.abuse-bot {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 180px !important;
}
.btn_abuse-bottom {
  width: 100% !important;
  max-width: none !important;
  text-align: center !important;
}
</style>
