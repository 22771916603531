<template>
  <modal :title="title" @hide="() => $emit('hide')" :show="show">
    <div></div>
    <div
      @click="
        () => {
          $emit(`option-${option.id}`);
          $emit('hide');
        }
      "
      class="modal--option"
      v-for="option in options"
      :key="`${option.id}-optnmdlDweaA`"
    >
      {{ option.name }}
    </div>
  </modal>
</template>
<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    title: String,
    options: Array,
  },
};
</script>

<style lang="scss" scoped>
.modal--option {
  text-align: center;
  line-break: anywhere;
  padding: 8px;
  cursor: pointer;
}
.modal--option:not(last-child) {
  border-bottom: 1px solid #d5dfe4;
}
.modal--option:hover {
  background: #3897f0;
}
</style>
